<div style="height: 100%; width: 100%" (ngxResize)="onResized($event)">
	<div>
		<lib-centered-message *ngIf="widgetObject !== undefined && widgetObject?.WidgetAssetId == null" centeredMessage="Please search for an asset from the Navigation tab on the left."></lib-centered-message>		
	</div>
	<div *ngIf="options.length > 0" class="flex-container" style="position: relative">
		<lib-navigation [options]="options" (opened)="checkNavigation($event)" (selected)="checkSelectedItem($event)"></lib-navigation>
		<div [ngClass]="navigationOpened ? 'main-content-with-nav-visible' : 'main-content-with-nav-invisible'">
			<lib-loading-spinner *ngIf="isLoading" [loadingMessage]="'Loading GSE Data...'" [vehicle]="'plane'"></lib-loading-spinner>
			<div *ngIf="!isLoading && widgetObject?.WidgetAssetId" class="card card-tasks" style="max-height: 900px; height:100%">
				<div (ngxResize)="onResized($event)" [style.max-height.px]="900" [style.height.%]="100">
					<lib-kendo-grid-parent 
						[gridData]="assetTags" 
						[gridSettings]="gridSettings" 
						[widgetObject]="widgetObject" 
						(callSave)="saveGridSettings()" 
						[style.height.%]="100" 
						filterable="menu" 
						[sortable]="true" 
						[reorderable]="true" 
						[resizable]="true" 
						*ngIf="assetTags !== undefined" 
						#tagDataGrid> 
					</lib-kendo-grid-parent>
					<!-- <input class="search-text" type="text" placeholder="Enter text to search..." (ngModelChange)="searchForText($event)" style="background-color: white !important; color:black !important;" />
					<table id="t1" style="border-bottom-style: solid; padding:0; margin:0; border-collapse: collapse;" >
						<thead>
							<tr style="display: table-row" class="rdn-header-row">
								<th>Id</th>
								<th>Name</th>
								<th>Standard Name</th>
								<th>Std Obs Id</th>
								<th>Value</th>
								<th>UTC Date</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let tag of assetTagsOrderByDateInMilliseconds() ; let i = index" [ngClass]="{ 'recentlyUpdated' : tag.RecentlyUpdated }">
								<td>
									{{tag.Id}}
								</td>
								<td>
									{{tag.Name}}
								</td>
								<td>
									{{tag.ShortTagName}}
								</td>
								<td>
									{{tag.JBTStandardObservationId}}
								</td>
								<td>
									{{tag.Value }}
								</td>
								<td>
									{{tag.UTCDateFull | date :'MM/dd/yyyy&nbsp;HH:mm:ss.SSS' }}
								</td>
							</tr>
						</tbody>
					</table> -->
				</div>
			</div>
		</div>
	</div>
</div>
